<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">极速跟单</h5>
        </a-col>
        <a-col :span="24" :md="12">
        </a-col>
      </a-row>
    </template>
<!--    <a-row type="flex" align="middle" style="justify-content: center;">-->
<!--      <a-space direction="vertical" :size="16" style="width: 71%;">-->
<!--        <a-alert-->
<!--            message="风险提示："-->
<!--            description="一个交易账户建议只接入一个交易API，否则多个交易员同时操作一个账户会导致资金风险，风险自负！如需分仓交易，请准备多个账户（或平台子账户），分别添加对应API。"-->
<!--            type="error"-->
<!--            show-icon-->
<!--            closable-->
<!--        />-->
<!--      </a-space>-->
<!--    </a-row>-->
    <a-form
        :form="form"
        class="mt-30"
        :hideRequiredMark="true"
        style="padding: 0 24px;"
    >
      <a-row type="flex" :gutter="[24]">
        <a-col :span="24" :md="20" :lg="18" class="mx-auto">
          <a-row class="checkbox-group" type="flex" :gutter="[50]">
            <a-col :span="12">
              <div class="checkbox-label"
                   :class="[selectedOption === 'API跟单' ? 'active' : '']"
                   @click="toggleCheckbox('API跟单')"
              >
                <h6>API跟单</h6>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="checkbox-label"
                   :class="[selectedOption === '小号共享仓位' ? 'active' : '']"
                   @click="toggleCheckbox('小号共享仓位')"
              >
                <h6>小号共享仓位</h6>
              </div>
            </a-col>
          </a-row>
          <a-radio-group class="d-none" v-model="selectedOption" :options="checkboxOptions"/>
        </a-col>
      </a-row>


      <a-row :gutter="[24]" type="flex" justify="center" style="margin-top: 46px;" v-if="selectedOption == 'API跟单'">
        <!--搜索框-->
        <a-input-search
            placeholder="输入API名称"
            enter-button="搜索"
            @search="apiSearch"
            style="width: 72.5%"
            size="large"
        />
        <!--搜索框-->

        <!--提示-->
        <a-row type="flex" align="top" style="margin: 30px 0;flex-direction: column;" v-if="api_list.length==0">
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 0;">
            <a-icon type="exclamation-circle" theme="outlined" />
            API跟单说明：
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            当前仅支持OKX，币安正在开发中...
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            搜索并选择你想跟单的API。当该API产生交易时，您的跟单账号也将同步进行交易。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            使用API进行跟单，效果堪比在交易所跟单，使用官方接口实时交易。
          </p>
          <br/>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 0;">
            <a-icon type="check-circle" theme="outlined" />
            使用技巧：
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            - 以OKX交易所为例，可以使用自己的小号在交易所进行跟单，可同时跟单一个或多个交易员。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            将小号的API提交到跟单猿，使用大号跟随小号，将实现0分润实时跟单。效果和在交易所跟单完全一致！
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            此方法币安不适用！
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            - 针对社区运营者，可将自己的API提交到跟单猿，让他人通过API跟单，跟随您的API进行交易。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            既可实现私域带单，同时也可避免被交易所或他人监控。
          </p>
        </a-row>
        <!--提示-->

        <!--搜索结果-->
        <a-table
            :columns="api_columns"
            :data-source="api_list"
            :pagination="false"
            style="width: 72.5%;margin-top: 30px;margin-bottom: 30px;border: 1px solid #ddd;border-radius: 8px;"
            v-else
        >

          <template slot="api_name" slot-scope="api_name">
            {{api_name}}
          </template>

          <template slot="exchange" slot-scope="exchange">
            <a-space :size="-12" class="avatar-chips">
              <a-avatar size="small" :src="exchange" />
            </a-space>
          </template>

          <template slot="user_id" slot-scope="user_id">
            {{user_id}}
          </template>

          <template slot="usdt" slot-scope="usdt">
            <span :style="{ color: '#31bd65', 'font-weight': '600' }">{{ usdt }} USDT</span>
          </template>

          <template slot="api_id" slot-scope="api_id">
            <a-button type="primary" size="small" :data-id="api_id" v-if="!vip || $store.state.userInfo.is_vip" @click="taskadd(api_id, 6)">
              立即跟单
            </a-button>
            <a-button type="danger" size="small" v-else-if="vip && !$store.state.userInfo.is_vip" @click="goToPricing">
              仅支持VIP跟单
            </a-button>
          </template>

        </a-table>
        <!--搜索结果-->
      </a-row>

      <a-row :gutter="[24]" type="flex" justify="center" style="margin-top: 46px;" v-if="selectedOption == '小号共享仓位'">
        <!--搜索框-->
        <a-input-search
            placeholder="输入共享仓位账号名称"
            enter-button="搜索"
            @search="onSearch"
            style="width: 72.5%"
            size="large"
        />
        <!--搜索框-->

        <!--提示-->
        <a-row type="flex" align="top" style="margin: 30px 0;flex-direction: column;">
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 0;">
            <a-icon type="exclamation-circle" theme="outlined" />
            共享仓位跟单说明：
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            此跟单模式目前仅适用于币安。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            使用小号在币安交易所跟单后，可获取带单项目的实时仓位信息，公域带单和私域带单都适用。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            需要每4天更新一次小号的cookie信息，否则无法获取实时仓位信息。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            超过4天未更新cookie，将转为普通跟单模式，公域带单项目将会有2分钟延迟，私域带单项目将无法继续跟单。
          </p>
          <br/>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            添加小号Cookie：<a href="https://github.com/binance/binance-docs/blob/master/web-api/binance-coin-margined-futures-api-guide.md#cookie-authentication" target="_blank">点击前往</a>
          </p>
        </a-row>
        <!--提示-->
      </a-row>
    </a-form>

  </a-card>

</template>

<script>
import http from "../utils/http"
import { message } from 'ant-design-vue';

const key = 'updatable';

const api_columns = [
  {
    title: 'API名称',
    dataIndex: 'api_name',
    scopedSlots: { customRender: 'api_name' },
  },
  {
    title: '交易所',
    dataIndex: 'exchange',
    scopedSlots: { customRender: 'exchange' },
  },
  {
    title: 'UID',
    dataIndex: 'user_id',
    scopedSlots: { customRender: 'user_id' },
  },
  {
    title: '资金',
    dataIndex: 'usdt',
    scopedSlots: { customRender: 'usdt' },
  },
  {
    title: '',
    dataIndex: 'api_id',
    scopedSlots: {customRender: 'api_id'},
    width: 50,
  },
];

function transformItem(item) {
  let imagePath;
  switch(item.exchange) {
    case 1:
      imagePath = 'images/okx.png';
      break;
    case 2:
      imagePath = 'images/binance.png';
      break;
    default:
      imagePath = 'images/okx.png'; // 默认图片
  }
  return {
    api_name: item.api_name,
    api_id: item.api_id,
    user_id: item.user_id,
    exchange: imagePath,
    usdt: item.usdt,
  }
}

export default {
  name: "SpeedCopy",
  components: {},
  data() {
    return {
      vip: true,
      // 选择模式
      selectedOption: 'API跟单',

      // Checkbox'es array of all options.
      checkboxOptions: ['API跟单', '小号共享仓位', '更多'],
      // Step's form object
      form: this.$form.createForm(this, {name: 'SpeedCopy'}),

      // api搜索
      api_columns,
      api_list: [],

      // 提交状态
      isLoading: false,
    }
  },
  methods: {
    // Toggle an item from the checkbox.
    toggleCheckbox(item) {
      if (item === '更多') {
        this.selectedOption = 'API跟单';
        return;
      } else {
        this.selectedOption = item;
        return;
      }
    },
    toplatform(selectedOption) {
      if (selectedOption === 'API跟单') {
        return '1';
      } else if (selectedOption === '小号共享仓位') {
        return '2';
      } else if (selectedOption === '更多') {
        return '3';
      }
    },

    // 搜索api
    async apiSearch(value) {
      this.$message.loading({ content: 'API搜索中，请稍后...', key, duration: 0 });
      try {
        const response = await http.post('/api/speedtrade/?token=' + localStorage.getItem("token"), {
          search_name: value,
        });

        if (response.data.code === 0) {
          // 更新用户数据
          const data = response.data.data;
          this.api_list = data.filter(item => item.exchange === 1).map(item => transformItem(item));

          this.$message.success({ content: '数据加载成功', key, duration: 2 });
        } else {
          this.$message.error({ content: response.data.error, key, duration: 2 });
        }
      } catch (error) {
        this.$message.error({ content: '暂无数据', key, duration: 2 });
      }
    },

    // 跳转vip
    goToPricing(){
      this.$router.push({path:'/pricing'})
    },

    // 立即跟单
    taskadd(id, trader_platform) {
      // 导航到 Task-Add 页面
      this.$router.push({
        path: `/speedcopy/taskadd/${trader_platform}/${id}`,
      });
    },
  },

}
</script>

<style scoped>
.mt-30 {
  font-weight: 600;
}



</style>